import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <hr/>
        <div>
          <p>7-Eleven, Inc. 3200 Hackberry Road, Irving, TX 75063</p>
          <p>1-866-467-8688, Service Code: 711</p>
          <p>privacypolicy@7-11.com</p>
        </div>

      </footer>
    );
  }
}

export default Footer;
