import withAuthProvider from './components/AuthProvider';
import React, { Component } from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Welcome from './components/Welcome';
import Home from './components/Home';
import SevenElevenBg from "./assets/Images/7-Eleven-bg.jpg";
import "./assets/css/reset.css";
import "./assets/css/App.css";
require('dotenv').config()

class App extends Component {
  render() {
    return (
      <Router>
        {this.props.isAuthenticated ? 
        <Home
          isAuthenticated={this.props.isAuthenticated}
          authButtonMethod={this.props.isAuthenticated ? this.props.logout : this.props.login}
          user={this.props.user}
          access_token={this.props.access_token}
        />:
        <div className="App vertical-flex sign-in-page">  
          <img 
            src={SevenElevenBg} 
            width="100%" 
            height="100%"
            alt="Seven Eleven Logo"
          />        
          <Route exact path="/"
            render={(props) =>    
              <Welcome {...props}
                  isAuthenticated={this.props.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} 
              />}
            /> 
          </div> 
        }
      </Router>
    );
  }
}

export default withAuthProvider(App);
