import {getUserDetails} from './GraphService';
import React from 'react';
import {PublicClientApplication} from '@azure/msal-browser';
import {config} from '../config';
import axios from '../../node_modules/axios'

export default function withAuthProvider(WrappedComponent) {
  return class extends React.Component {
    publicClientApplication;
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isAuthenticated: false,
        user: {},
        access_token:''
      };      
    }

    componentDidMount() {
      this.getAuthenticate();
    }

    async getAuthenticate() {  
      var obj = {"client_id":"SP_CLIENT_ID", "client_secret":"SP_CLIENT_SECRET",
                 "grant_type":"client_credentials", "resource":"RESOURCE",
                 "url":"AZURE_TENANT_ID"};
      try {
        const response = await axios({
          url: '/authenticate/token',
          method: 'POST',
          data: obj
        })
        this.setState({ access_token:response.data.access_token })
        this.getappId();
      } 
      catch (e) {
        console.log(e);
      }
    }

    async getappId() {
      var obj = {"access_token":this.state.access_token, "client_id":"REACT_APP_AP_CLIENT_ID"};
      try {
        const response = await axios({
          url: 'clientid/keyvault',
          method: 'POST',
          data: obj
        })
        this.setState({ appId:response.data })
        this.callMSALClientApp();
      } 
      catch(e) {
        console.log(e);
      } 
    }

    callMSALClientApp(){
      // Initialize the MSAL application object
      this.publicClientApplication = new PublicClientApplication({
        auth: {
          clientId: this.state.appId,
          redirectUri: process.env.REACT_APP_REDIRECTURI
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: true
        }
      });
      // If MSAL already has an account, the user
      // is already logged in
      const accounts = this.publicClientApplication.getAllAccounts();
      if (accounts && accounts.length > 0) {
        // Enhance user object with data from Graph
        this.getUserProfile();
      }
    }

    render() {
      return <WrappedComponent
        error={this.state.error}
        isAuthenticated={this.state.isAuthenticated}
        user={this.state.user}
        access_token={this.state.access_token}
        login={() => this.login()}
        logout={() => this.logout()}
        getAccessToken={(scopes) => this.getAccessToken(scopes)}
        setError={(message, debug) => this.setErrorMessage(message, debug)}
        {...this.props} {...this.state} />;
    }

    async login() {
      try {
        // Login via popup
        await this.publicClientApplication.loginPopup(
          {
            scopes: config.scopes,
            prompt: "select_account"
          });
        // After login, get the user's profile
        await this.getUserProfile();
      } catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err)
        });
      }
    }

    logout() {
      this.publicClientApplication.logout();
    }

    async getAccessToken(scopes) {
      try {
        const accounts = this.publicClientApplication.getAllAccounts();
        if (accounts.length <= 0) throw new Error('login_required');
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await this.publicClientApplication
          .acquireTokenSilent({
            scopes: scopes,
            account: accounts[0]
          });
        return silentResult.accessToken;
      } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (this.isInteractionRequired(err)) {
          var interactiveResult = await this.publicClientApplication
            .acquireTokenPopup({
              scopes: scopes
            });

          return interactiveResult.accessToken;
        } else {
          throw err;
        }
      }
    }

    async getUserProfile() {
      try {
        var accessToken = await this.getAccessToken(config.scopes);
        if (accessToken) {
          // get the user's profile from graph
          var user = await getUserDetails(accessToken);
          this.setState({
            isAuthenticated: true,
            user: {
              displayName: user.displayName,
              email: user.mail || user.userPrincipalName,
              timezone: user.mailboxSettings.timeFormat
            },
            error: null

          });
        }
      } catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err)
        });
      }
    }

    setErrorMessage(message, debug) {
      this.setState({
        error: {message: message, debug: debug}
      });
    }

    normalizeError(error) {
      var normalizedError = {};
      if (typeof (error) === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ?
          {message: errParts[1], debug: errParts[0]} :
          {message: error};
      } else {
        normalizedError = {
          message: error.message,
          debug: JSON.stringify(error)
        };
      }
      return normalizedError;
    }

    isInteractionRequired(error) {
      if (!error.message || error.message.length <= 0) {
        return false;
      }
      return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
      );
    }
  }
}
