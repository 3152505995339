import React, { Component } from "react";
import axios from '../../node_modules/axios';
import Papa from '../../node_modules/papaparse';
import SelectSearch from 'react-select-search';
import Header from '../components/Header';
import Footer from '../components/Footer';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadTemplateName: '',
      uploadTemplateName:'',
      uploadFolder: '',
      downloadBtnInactive: true,
      uploadBtnInactive: true,
      uploadResponse: '',
      uploadResponseErr: true,
      data : {
        csvfile: ''
      }
    };
    this.updateData = this.updateData.bind(this);
  }

  onDownloadDropdownChange(e) {
    this.setState({ 
      downloadTemplateName: e,
      downloadBtnInactive: false
    });
  }

  async onDownloadButton() {
    var obj = {"downloadTemplateName": this.state.downloadTemplateName}
    try {
      const response = await axios({
        url: 'servercall/download',
        method: 'POST',
        data: obj
      })
      this.download(this.state.downloadTemplateName,response.data);
    } 
    catch(e) {
      console.log(e)
    }
  }

  download(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
  }

  onUploadDropdownChange(e) {
    this.setState({
      uploadFolder: e,
      uploadBtnInactive: false
    });
  }
  
  onHandleFileUpload(event) {
      var data = {...this.state.data}
      data.csvfile = event.target.files[0]
      this.setState({data})
      if(data.csvfile !== undefined) {
        this.setState({ uploadTemplateName:event.target.files[0].name })
      }
  }

  onUploadButton() {
    if(this.state.uploadTemplateName.includes(this.state.uploadFolder)){
      const { csvfile } = this.state.data;
      Papa.parse(csvfile, {
        complete: this.updateData,
        header: true,
        skipEmptyLines: true,
        download: true
      });
      this.setState({ uploadResponseErr: true })
    }
    else {
      this.setState({ 
        uploadResponseErr: false,
        uploadResponse: ''
      })
    }
  }

  async updateData(result) {
    var data = result.data;
    var obj = {"csvdata": result.data, "filename": this.state.uploadTemplateName, "upload": this.state.uploadFolder}
    try {
      const response = await axios({
        url: 'servercall/upload',
        method: 'POST',
        data: obj
      })
      this.setState({ uploadResponse:response.data })
    } 
    catch(e) {
      console.log(e)
      this.setState({ 
        uploadResponseErr: false,
        uploadResponse: ''
      })
    }
  }

  render() {
    const DownloadOptions = [
      {name: 'Remodel.csv', value: 'Remodel.csv'},
      {name: 'Restaurant.csv', value: 'Restaurant.csv'},
      {name: 'Store.csv', value: 'Store.csv'}
    ]
    const UploadOptions = [
      {name: 'Remodel', value: 'Remodel'},
      {name: 'Restaurant', value: 'Restaurant'},
      {name: 'Store', value: 'Store'}
    ]

    return (
      <div className="App">
        <Header 
          isAuthenticated={this.props.isAuthenticated}
          authButtonMethod={this.props.authButtonMethod}
          user={this.props.user}
        />
        <section className="main-section">
          <div className="download-section">
            <SelectSearch 
              options={DownloadOptions} 
              search
              value={this.state.downloadTemplateName}
              placeholder="Select a template to download" 
              onChange={this.onDownloadDropdownChange.bind(this)}
            />
            {this.state.downloadBtnInactive ?
              <button 
                className="mdm-button button-inactive position-right"
                onClick={this.onDownloadButton.bind(this)}> Download 
              </button> :
              <button 
                className="mdm-button position-right"
                onClick={this.onDownloadButton.bind(this)}> Download 
              </button>
            }
          </div>
          <div className="upload-section">
            <input type="file" 
              className="position-left"
              onChange={this.onHandleFileUpload.bind(this)} 
              accept='.csv'
            />
            <SelectSearch 
              options={UploadOptions} 
              search
              value={this.state.uploadFolder}
              placeholder="Select a folder to upload" 
              onChange={this.onUploadDropdownChange.bind(this)}
            />
            {this.state.uploadBtnInactive ?
              <button 
                className="mdm-button button-inactive position-right"
                onClick={this.onUploadButton.bind(this)}> Upload 
              </button> :
              <button 
                className="mdm-button position-right margin-top-1"
                onClick={this.onUploadButton.bind(this)}> Upload 
              </button>
            }          
          </div>
          {this.state.uploadResponse !== '' ?
            <div className="upload-response">
              {this.state.uploadResponse}
            </div> : ''
          }
          {this.state.uploadResponseErr === false ?
            <div className="upload-response-err">
              The selected file cannot be uploaded.
            </div>:''
          }
        </section>
        <Footer />
      </div>
    );
  } 
}

export default Home;
