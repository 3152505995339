import React, { Component } from 'react';
import logo from '../../src/assets/Images/logo-white.png';
import NavBar from "./NavBar";

class Header extends Component {
  render() {
    return (
      <header className="app-header">
        <img className="header-logo" src={logo} alt='7-Eleven logo'/>
        <NavBar
          isAuthenticated={this.props.isAuthenticated}
          authButtonMethod={this.props.authButtonMethod}
          user={this.props.user}
        />
      </header>
    );
  }
}

export default Header;
