import React from 'react';
import {
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

function AuthNavItem(props) {
  if (props.isAuthenticated) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle nav caret>
          <i className="fa fa-user user-profile"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <h5 className="dropdown-item-text mb-0">{props.user.displayName}</h5>
          <p className="dropdown-item-text text-muted mb-0">{props.user.email}</p>
          <DropdownItem divider />
          <p onClick={props.authButtonMethod} className="user-sign-out">Sign Out</p>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default class NavBar extends React.Component {
  constructor(props) {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {   
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <div className="user-Profile">
        <Collapse  className="nav-container position-right" navbar>
          <Nav className="justify-content-end" navbar>
            <AuthNavItem
              isAuthenticated={this.props.isAuthenticated}
              authButtonMethod={this.props.authButtonMethod}
              user={this.props.user} 
            />
          </Nav>
        </Collapse>
      </div>
    );
  }
}
