import React from 'react';
import {
  Button,
  Jumbotron
} from 'reactstrap';

function WelcomeContent(props) {
  return <Button color="primary" className="sign-in-btn" onClick={props.authButtonMethod}>Sign In</Button>;
}

export default class Welcome extends React.Component {
  render() {
    return (
      <Jumbotron>
        <WelcomeContent
          isAuthenticated={this.props.isAuthenticated}
          user={this.props.user}
          authButtonMethod={this.props.authButtonMethod} />
      </Jumbotron>
    );
  }
}
